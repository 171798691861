import React, { useEffect } from 'react';
import { FaGlobe } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 0,
    });
  }, []);

  return (
    <footer className="bg-gray-900 text-white p-4 mt-auto text-center w-full">
      <div className="container mx-auto" data-aos="fade-right">
        <p className="text-sm">
          © 2024 Imaad | Design Project for SES141
        </p>
        <p className="text-sm flex justify-center items-center gap-2">
          View at <a href="http://energyvisualizer.com" className="underline hover:text-green-300 flex items-center gap-1">
            <FaGlobe /> energyvisualizer.com
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
