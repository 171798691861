import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

const stadiumEnergyUsage = 15000;
const amusementParkEnergyUsage = 13000;
const smartphoneChargeEnergy = 0.015;
const averageUSCitizenYearly = 10500;
const hospitalYearlyEnergy = 7657000;
const averageHouseholdEnergy = 1048/7;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const energyPrices = { // Price per kWh in cents
  "United States": 17,
  "Canada": 14,
  "United Kingdom": 44,
  "Australia": 28,
  "Germany": 40,
  "France": 28,
  "India": 8,
  "China": 8,
  "Japan": 22,
  "Brazil": 17
};
  
const estimateEnergyConsumption = (formData) => {
    const {
      numberOfRooms,
      drivingHoursPerWeek,
      homeTemperature,
      electronicDevices,
      entertainmentHours,
      energyConsciousness,
      country
    } = formData;
  

    const baselineTemp = 70;
    const baselineEnergyPerRoom = 20;
    const tempAdjustment = Math.pow(homeTemperature - baselineTemp, 2) / 30;
    const tempAdjustmentFixed = homeTemperature && numberOfRooms ? tempAdjustment : 0

    const roomEnergy = numberOfRooms * baselineEnergyPerRoom + tempAdjustmentFixed;


    const kWhPerHourDriving = 1.25 * 30;
    const driveEnergy = (drivingHoursPerWeek * kWhPerHourDriving) / 7;
  
    const kWhPerDevice = 0.1;
    const kWhPerEntertainmentHour = 0.1; 
    const deviceEnergy = ((electronicDevices * kWhPerDevice * 7 * 4) + (entertainmentHours * kWhPerEntertainmentHour * 4)) / 30;
  
    const consciousnessAdjustment = 1 - ((energyConsciousness / 100) * 0.1); // +/- 5% max variation
  
    const homeEnergy = roomEnergy * consciousnessAdjustment;
    const transportEnergy = driveEnergy * consciousnessAdjustment;
    const electronicEnergy = deviceEnergy * consciousnessAdjustment;
  
    const totalEnergy = homeEnergy + transportEnergy + electronicEnergy;
    const costPerKWh = energyPrices[country] / 100;
    const totalCost = totalEnergy * costPerKWh;
  
    return { homeEnergy, transportEnergy, electronicEnergy, totalEnergy, totalCost };
  };
  
const ResultsSection = ({ formData }) => {
  const { homeEnergy, transportEnergy, electronicEnergy, totalEnergy, totalCost } = estimateEnergyConsumption(formData);

  const data = {
    labels: ['Home Energy', 'Transport Energy', 'Electronic Energy'],
    datasets: [
      {
        label: 'Energy Consumption (kWh)',
        data: [homeEnergy, transportEnergy, electronicEnergy],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)'
        ],
        borderWidth: 1
      }
    ]
  };
  
  const options = {
    scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Energy Usage in kWh'
          }
        }
      },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          generateLabels: function(chart) {
            return chart.data.labels.map((label, index) => {
              const color = chart.data.datasets[0].backgroundColor[index];
              return {
                text: label,
                fillStyle: color,
                strokeStyle: chart.data.datasets[0].borderColor[index],
                lineWidth: chart.data.datasets[0].borderWidth,
                hidden: false,
                index: index
              };
            });
          }
        }
      },
      title: {
        display: true,
        text: 'Estimated Energy Consumption by Category'
      }
    }
  };


  return (
    <section id="results" className="container mx-auto p-8 bg-gray-50 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center mb-10 text-gray-800">Your Energy Usage Visualized</h2>
      
      <div id="dailyUsage" className="mb-12 p-4 bg-white rounded-lg shadow">
        <h3 className="text-2xl font-bold mb-4 text-blue-600">Daily Usage</h3>
        <Bar data={data} options={options} />
        <p className="mt-4 text-lg font-medium">Total Daily Energy Consumption: <span className="text-green-600">{totalEnergy.toFixed(2)} kWh</span></p>
        <p className="text-lg font-medium">Estimated Daily Cost: <span className="text-red-600">$ {totalCost.toFixed(2)}</span></p>
      </div>
      
      <div id="weeklyTrends" className="mb-12 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-5 text-blue-700">Weekly Info</h3>
        <hr className="my-4 border-gray-300" />
        <p className="text-lg font-medium">Total Weekly Energy Consumption: 
            <span className="text-green-600 font-bold"> {(totalEnergy * 7).toFixed(2)} kWh</span>
        </p>
        <br></br>
        <p className="text-lg font-medium">This could:</p>
        <ul className="list-disc ml-8">
            <li>Power <span className="text-green-600 font-bold">{((totalEnergy * 7) / averageHouseholdEnergy).toFixed(2)} average homes</span> for a week</li>
            <li>Brew <span className="text-green-600 font-bold">{((totalEnergy * 7) / 0.5).toFixed(0)} pots of coffee</span></li>
            <li>Charge <span className="text-green-600 font-bold">{((totalEnergy * 7) / smartphoneChargeEnergy).toFixed(0)} smartphones</span></li>
        </ul>
        <br></br>
        <p className="text-lg font-medium">Estimated Weekly Cost: 
            <span className="text-red-600 font-bold"> ${(totalCost * 7).toFixed(2)}</span>
        </p>
      </div>

      
      <div id="monthlyInsights" className="mb-12 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-5 text-blue-700">Monthly Insights</h3>
        <hr className="my-4 border-gray-300" />
        <p className="text-lg font-medium">Total Monthly Energy Consumption: 
            <span className="text-green-600 font-bold"> {(totalEnergy * 30).toFixed(2)} kWh</span>
        </p>
        <br></br>
        <p className="text-lg font-medium">Equivalent to:</p>
        <ul className="list-disc ml-8">
            <li>Powering a football stadium for <span className="text-green-600 font-bold">{((totalEnergy * 30) / stadiumEnergyUsage).toFixed(2)} days</span></li>
            <li>Running a small amusement park for <span className="text-green-600 font-bold">{((totalEnergy * 30) / amusementParkEnergyUsage).toFixed(2)} days</span></li>
            <li>Charging <span className="text-green-600 font-bold">{((totalEnergy * 30) / smartphoneChargeEnergy).toFixed(0)} smartphones</span></li>
        </ul>
        <br></br>
        <p className="text-lg font-medium">Estimated Monthly Cost: 
            <span className="text-red-600 font-bold"> ${(totalCost * 30).toFixed(2)}</span>
        </p>
      </div>

      
      <div id="yearlyOverview" className="mb-12 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-5 text-blue-700">Yearly Overview</h3>
        <hr className="my-4 border-gray-300" />
        <p className="text-lg font-medium">Total Yearly Energy Consumption: 
            <span className="text-green-600 font-bold"> {(totalEnergy * 365).toFixed(2)} kWh</span>
        </p>
        <br></br>
        <p className="text-lg font-medium">Breakdown:</p>
        <ul className="list-disc ml-8">
            <li>Home Energy: <span className="text-green-600 font-bold">{(homeEnergy * 365).toFixed(2)} kWh</span></li>
            <li>Transport Energy: <span className="text-green-600 font-bold">{(transportEnergy * 365).toFixed(2)} kWh</span></li>
            <li>Electronic Energy: <span className="text-green-600 font-bold">{(electronicEnergy * 365).toFixed(2)} kWh</span></li>
        </ul>
        <br></br>
        <p className="text-lg font-medium">Compared to Average U.S. Citizen:</p>
        <ul className="list-disc ml-8">
            <li>Your yearly consumption is <span className="text-orange-600 font-bold">{((totalEnergy * 365) / averageUSCitizenYearly).toFixed(2)} times</span> the national average</li>
            <li>You could charge your smartphone <span className="text-green-600 font-bold">{(((totalEnergy * 365) / smartphoneChargeEnergy) - (averageUSCitizenYearly / smartphoneChargeEnergy)).toFixed(0)}</span> more times</li>
            <li>This is equivalent to operating a small hospital for <span className="text-green-600 font-bold">{((totalEnergy * 365) / hospitalYearlyEnergy * 365).toFixed(2)} days</span></li>
        </ul>
        <br></br>
        <p className="text-lg font-medium">Estimated Yearly Cost: 
            <span className="text-red-600 font-bold"> ${(totalCost * 365).toFixed(2)}</span>
        </p>
       </div>

      
    </section>
  );  
}

export default ResultsSection;