import React from 'react';
import FormInput from '../components/FormInput';

const InputSection = ({ formData, setFormData }) => { 

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const countries = ["United States", "Canada", "United Kingdom", "Australia", "Germany", "France", "India", "China", "Japan", "Brazil"]; // Example countries

    return (
        <section id="input" className="container mx-auto p-8 bg-gray-100 rounded-lg shadow">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Your Daily Energy Habits</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormInput
                    label="Number of bedrooms in your home"
                    name="numberOfRooms"
                    type="number"
                    placeholder="Total rooms"
                    value={formData.numberOfRooms}
                    onChange={handleChange}
                />
                <FormInput
                    label="Average hours spent driving per week"
                    name="drivingHoursPerWeek"
                    type="number"
                    placeholder="Hours per week"
                    value={formData.drivingHoursPerWeek}
                    onChange={handleChange}
                />
                <FormInput
                    label="Preferred indoor temperature (°F)"
                    name="homeTemperature"
                    type="number"
                    placeholder="E.g., 72"
                    value={formData.homeTemperature}
                    onChange={handleChange}
                />
                <FormInput
                    label="Number of electronic devices used regularly"
                    name="electronicDevices"
                    type="number"
                    placeholder="Count devices"
                    value={formData.electronicDevices}
                    onChange={handleChange}
                />
                <FormInput
                    label="Hours spent on home entertainment systems per day"
                    name="entertainmentHours"
                    type="number"
                    placeholder="Hours per day"
                    value={formData.entertainmentHours}
                    onChange={handleChange}
                />
                <div className="col-span-full">
                    <label htmlFor="energyConsciousness" className="block text-gray-700 text-sm font-bold mb-2">
                        How would you describe how energy conscious you are?
                    </label>
                    <div className="flex justify-between items-center">
                        <input
                            type="range"
                            name="energyConsciousness"
                            min="0"
                            max="100"
                            value={formData.energyConsciousness}
                            onChange={handleChange}
                            className="w-full"
                        />
                        <span className="ml-2 text-gray-700 font-medium">{formData.energyConsciousness}%</span>
                    </div>
                </div>
                <div className="col-span-full">
                    <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
                        Select your country
                    </label>
                    <select
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                        {countries.map(country => (
                            <option key={country} value={country}>{country}</option>
                        ))}
                    </select>
                </div>
            </div>
            <p className="mt-6 text-gray-600">
                Help understand your energy footprint better by giving accurate inputs!
            </p>
        </section>
    );
};

export default InputSection;
