import React from 'react';
import { FaSun, FaCalendarWeek, FaRegCalendar, FaRegCalendarAlt } from 'react-icons/fa';  // Icons for daily, weekly, monthly, yearly

const Introduction = () => {
  return (
    <section id="introduction" className="container mx-auto p-8 bg-gray-50 shadow-md rounded-lg mt-8">
      <div className="text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Welcome to my Energy Visualizer</h2>
        <p className="text-lg text-gray-600 mb-6">
          Analyze your energy consumption with smart estimates and comparisons!
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-center">
        <div className="p-4 hover:bg-gray-200 rounded-lg transition duration-300 ease-in-out cursor-pointer" onClick={() => window.location = '#dailyUsage'}>
          <FaSun className="mx-auto text-3xl text-yellow-500 mb-2"/>
          <p className="font-semibold">Daily Usage</p>
        </div>
        <div className="p-4 hover:bg-gray-200 rounded-lg transition duration-300 ease-in-out cursor-pointer" onClick={() => window.location = '#weeklyTrends'}>
          <FaCalendarWeek className="mx-auto text-3xl text-blue-500 mb-2"/>
          <p className="font-semibold">Weekly Info</p>
        </div>
        <div className="p-4 hover:bg-gray-200 rounded-lg transition duration-300 ease-in-out cursor-pointer" onClick={() => window.location = '#monthlyInsights'}>
          <FaRegCalendar className="mx-auto text-3xl text-green-500 mb-2"/>
          <p className="font-semibold">Monthly Insights</p>
        </div>
        <div className="p-4 hover:bg-gray-200 rounded-lg transition duration-300 ease-in-out cursor-pointer" onClick={() => window.location = '#yearlyOverview'}>
          <FaRegCalendarAlt className="mx-auto text-3xl text-red-500 mb-2"/>
          <p className="font-semibold">Yearly Overview</p>
        </div>
      </div>
    </section>
  );
}

export default Introduction;