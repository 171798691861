import React from 'react';

const FormInput = ({ label, name, type = 'text', placeholder, value, onChange, options = [] }) => {
    const renderInput = () => {
        switch (type) {
            case 'select':
                return (
                    <select
                        name={name}
                        id={name}
                        value={value}
                        onChange={onChange}
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                        {options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                );
            case 'range':
                return (
                    <input
                        type="range"
                        name={name}
                        id={name}
                        min="0"
                        max="100"
                        value={value}
                        onChange={onChange}
                        className="w-full"
                    />
                );
            default:
                return (
                    <input
                        type={type}
                        name={name}
                        id={name}
                        placeholder={placeholder}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={value}
                        onChange={onChange}
                    />
                );
        }
    };

    return (
        <div className="mb-4">
            <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
                {label}
            </label>
            {renderInput()}
        </div>
    );
};

export default FormInput;
