import React from 'react';

const SuggestionsSection = () => {
  return (
    <section id="suggestions" className="container mx-auto p-8 bg-gray-50 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center mb-10 text-blue-600">Energy Saving Suggestions</h2>
      <p className="text-lg font-medium text-gray-800">Here are some ways you can reduce your energy consumption:</p>
      <ul className="list-disc pl-10 mt-4 text-lg font-medium text-gray-800">
        <li>Ensure your home is properly insulated to save on heating and cooling costs</li>
        <li>Switch to LED lighting</li>
        <li>Consider seasonal energy saving tips</li>
        <li>Look into energy efficient appliances and alternatives</li>
      </ul>
      <div className="mt-6">
        <h3 className="text-xl font-bold text-blue-600">Learn More:</h3>
        <br></br>
        <p className="text-lg font-medium">
          <a href="https://www.energy.gov/energysaver/spring-and-summer-energy-saving-tips" className="text-blue-800 hover:text-blue-500" target="_blank" rel="noopener noreferrer">Spring and Summer Energy Saving Tips</a>
        </p>
        <p className="text-lg font-medium">
          <a href="https://www.energy.gov/energysaver/fall-and-winter-energy-saving-tips" className="text-blue-800 hover:text-blue-500" target="_blank" rel="noopener noreferrer">Fall and Winter Energy Saving Tips</a>
        </p>
        <p className="text-lg font-medium">
          <a href="https://www.energy.gov/clean-energy" className="text-blue-800 hover:text-blue-500" target="_blank" rel="noopener noreferrer">Learn More About Clean Energy</a>
        </p>
      </div>
    </section>
  );
}

export default SuggestionsSection;