import React, { useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Introduction from './views/Introduction';
import InputSection from './views/InputSection';
import ResultsSection from './views/ResultsSection';
import SuggestionsSection from './views/SuggestionsSection';
import './App.css';

function App() {
  const [formData, setFormData] = useState({
    numberOfRooms: '',
    drivingHoursPerWeek: '',
    homeTemperature: '',
    electronicDevices: '',
    entertainmentHours: '',
    energyConsciousness: 50,
    country: 'United States'
  });

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow pt-24">
        <Introduction />
        <div className="my-12">
          <InputSection formData={formData} setFormData={setFormData} />
        </div>
        <div className="my-12">
          <ResultsSection formData={formData} />
        </div>
        <div className="my-12">
          <SuggestionsSection />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;