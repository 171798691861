import React, { useState, useEffect } from 'react';
import { FaBars, FaChartLine, FaHome, FaInfoCircle, FaTools, FaTimes } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="w-full bg-gray-900 text-white px-4 py-2 fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold" data-aos="fade-right">Energy Visualizer</h1>
        <div className="flex items-center">
          <button onClick={toggleMenu} className="text-xl md:hidden mr-4">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`${isOpen ? 'block' : 'hidden'} absolute md:relative md:flex w-full md:w-auto bg-gray-900 md:bg-transparent top-full left-0 md:block`}>
            <ul className="flex flex-col md:flex-row md:space-x-10 p-4 md:p-0">
              <li>
                <a href="#introduction" className="block py-2 text-white hover:text-green-300 transition duration-300 ease-in-out">
                  <FaHome className="inline mr-2" /> Introduction
                </a>
              </li>
              <li>
                <a href="#input" className="block py-2 text-white hover:text-green-300 transition duration-300 ease-in-out">
                  <FaChartLine className="inline mr-2" /> Input Data
                </a>
              </li>
              <li>
                <a href="#results" className="block py-2 text-white hover:text-green-300 transition duration-300 ease-in-out">
                  <FaTools className="inline mr-2" /> Results
                </a>
              </li>
              <li>
                <a href="#suggestions" className="block py-2 text-white hover:text-green-300 transition duration-300 ease-in-out">
                  <FaInfoCircle className="inline mr-2" /> Suggestions
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;